import { View } from "../services/data";

export interface App {
  id: string;
  projectID: string;
  name: string;
  appName: string;
  appID: string;
  description?: string;
  createdAt: number;
  updatedAt: number;
  appIcon?: string;
  settings?: AppSettings;
  status: AppStatus;
  version: string;
  views?: View[];
  theme?: AppTheme;
}

export interface AppTheme {
  colors?: Record<string, string>;
}

export interface AppView {
  id: string;
  name: string;
  description?: string;
  createdAt: string;
  updatedAt: string;
}

export interface AppSettings {
  platform?: {
    ios?: boolean;
    android?: boolean;
  };
  buildConfig?: {
    environment: 'development' | 'production';
    target: string[];
  };
  deployment?: {
    store?: {
      ios?: string;
      android?: string;
    };
    configuration?: Record<string, unknown>;
  };
}

export enum AppStatus {
  DEVELOPMENT = 'development',
  TESTING = 'testing',
  PUBLISHED = 'published',
  ARCHIVED = 'archived'
}

export type AppSummary = Pick<App, 'id' | 'name' | 'appName' | 'appID' | 'status'>;

export type NewApp = Omit<App, 'id' | 'createdAt' | 'updatedAt'> & {
  template?: string;
};
